<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$router.back()" />
    </template>
    <template #default>
      <div class="search">
        <inputBoxVue
          :keydownEnter="inputsearch"
          v-model="serchvalue"
          :placeholder="$fanyi('可根据商品名称模糊匹配')"
        />
      </div>
    </template>
    <!--收藏商品页面 -->
  </headBackBar>
  <myDialog ref="MyDialog" />
  <!-- 列表区域 -->
  <div class="content">
    <van-pull-refresh
      v-model="refreshing"
      head-height="100"
      :loosing-text="$fanyi('释放即可刷新...')"
      :pulling-text="$fanyi('下拉刷新')"
      :loading-text="$fanyi('加载中')"
      :success-text="$fanyi('加载成功')"
      @refresh="onRefresh"
    >
      <van-list
        v-model:loading="loading"
        :loading-text="$fanyi('加载中')"
        :finished="finished"
        :finished-text="$fanyi('')"
        v-model:error="error"
        :error-text="$fanyi('请求失败，点击重新加载')"
        @load="onLoad"
      >
        <!-- 每一件商品 -->
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="$fun.toCommodityDetails(item.goods_id, item.shop_type)"
        >
          <div class="left-img">
            <van-image :src="item.image_url" lazy-load>
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
          <div class="rightext">
            <!-- 标题区域 -->
            <div class="top-title">
              <span class="icon1688" v-if="item.shop_type == '1688'">
                <img :src="require('@/assets/icon/icon1688.png')" alt="" />
              </span>
              {{ item.title }}
            </div>
            <div class="footer">
              <!-- 价格 -->
              <p class="price">
                <span>
                  {{ Number(item.price).toFixed(2) }}{{ $fanyi("元") }}</span
                >
                ({{
                  (Number(item.price) * $store.state.exchangeRate).toFixed(0)
                }}
                {{ $fanyi("円") }})
              </p>
              <!-- 删除 -->
              <div class="delete" @click.stop="deleted(item)">
                {{ $fanyi("删除") }}
              </div>
            </div>
          </div>
        </div>
        <div class="noData" v-if="!list.length && !loading">
          <div>
            <img :src="require('@/assets/icon/scj.png')" alt="" />
            <span>{{ $fanyi("收藏夹暂无内容") }}</span>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="technicalSupport">
      {{ $fanyi("由1688提供部分技术服务⽀持") }}
    </div>
  </div>
</template>
<script setup>
import myDialog from "@/components/Dialog";
import inputBoxVue from "@/components/inputBox/index.vue";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref, watch } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();
const serchvalue = ref("");
const page = ref(0);
var timer = null;
// 输入框发生变化开始模糊搜索
const inputsearch = (e) => {
  if (timer != null) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    if (serchvalue.value != "") {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
        loadingType: "spinner",
      });
      searchlist.value = [];
      page.value = 0;
      list.value = [];
      onLoad();
    }
  }, 1000);
};
Toast.loading({
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});
// favoriteGoodsDelete
const list = ref([]);
const loading = ref(false);
const finished = ref(false);
const alllist = ref([]);
const searchlist = ref([]);
const error = ref(false);
const refreshing = ref(false);

// 触底触发
const onLoad = async () => {
  if (serchvalue.value == "") {
    finished.value = false;
    searchlist.value = [];

    page.value += 1;
    const res = await proxy.$api.favoriteGoodsList({
      goods_status: "上架中",
      page: page.value,
      pageSize: 20,
    });
    if (res.code !== 0) {
      Toast.clear();
      error.value = true;
      return;
    }
    // 进行判断是下拉海是触底
    // 说明是下滑 如果是下滑就把数据放到前面，触底把数据放到后面
    if (refreshing.value == true) {
      alllist.value = [...res.data?.data, ...alllist.value];
    } else {
      alllist.value = [...alllist.value, ...res.data?.data];
    }
    list.value = alllist.value;
    loading.value = false;
    Toast.clear();
    refreshing.value = false;

    if (res.data.data.length < 10 || res.data.total == alllist.length) {
      finished.value = true;
    }
  } else {
    alllist.value = [];
    searchlist.value = [];
    list.value = [];
    page.value += 1;
    const res = await proxy.$api.favoriteGoodsList({
      goods_status: "上架中",
      page: page.value,
      pageSize: 20,
      keywords: serchvalue.value,
    });
    if (res.code !== 0) {
      Toast.clear();
      error.value = true;
      return;
    }
    // 进行判断是下拉海是触底
    // 说明是下滑 如果是下滑就把数据放到前面，触底把数据放到后面
    if (refreshing.value == true) {
      searchlist.value = [...res.data?.data, ...searchlist.value];
    } else {
      searchlist.value = [...searchlist.value, ...res.data?.data];
    }
    list.value = searchlist.value;
    loading.value = false;
    Toast.clear();
    refreshing.value = false;

    if (res.data.data.length < 10 || res.data.total == searchlist.length) {
      finished.value = true;
    }
  }
};
// 下拉触发
const onRefresh = () => {
  // 如果为true 说明数据加载完成没有新数据，return 不调用接口
  if (finished.value == true) {
    refreshing.value = false;
    return;
  }
  onLoad();
};
// 删除收藏
const deleted = async (item) => {
  proxy.$refs.MyDialog.open({
    text: proxy.$fanyi("是否删除商品"),
    successBtn: proxy.$fanyi("确认"),
    cancelBtn: proxy.$fanyi("取消"),
    successMethods: async () => {
      Toast.loading({
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
        loadingType: "spinner",
      });
      const res = await proxy.$api.favoriteGoodsDelete({
        delete: [
          {
            shop_type: item.shop_type,
            goods_id: item.goods_id,
          },
        ],
      });
      if (res.code !== 0) {
        Toast.fail(proxy.$fanyi(res.msg));
        Toast.clear();
        return;
      }
      Toast.success(proxy.$fanyi("删除成功"));
      Toast.clear();
      page.value = 0;
      finished.value = false;
      alllist.value = [];
      searchlist.value = [];
      onLoad();
      proxy.$refs.MyDialog.show = false;
    },
  });
};
// 件事输入框的值
watch(serchvalue, (newvalue, oldvalue) => {
  if (serchvalue.value == "") {
    Toast.loading({
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
      loadingType: "spinner",
    });
    page.value = 0;
    list.value = [];
    searchlist.value = [];
    finished.value = false;
    onLoad();
  }
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

.search {
  width: 570px;
}

.content {
  width: 100%;
  background: #f6f6f6;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: -128px;
  padding-top: 148px;
  // padding-bottom: 100px;

  .item {
    padding: 30px;
    width: 690px;
    height: 240px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    margin-bottom: 20px;

    .left-img {
      width: 180px;
      flex: 0 0 180px;
      height: 180px;
      background: #f0f0f0;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      :deep() .van-image {
        img {
          width: 180px;
          height: 180px;
          background: #ffffff;
          border-radius: 6px;
        }
      }
    }

    .rightext {
      padding-left: 21px;

      .top-title {
        height: 78px;
        font-size: 28px;

        font-weight: 400;
        color: #000000;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 45px;
        .icon1688 {
          width: 76px;
          height: 30px;
          background: #ff4000;
          border-radius: 2px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          img {
            width: 52px;
          }
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
          font-size: 24px;
          font-weight: 400;
          color: #b4272b;

          span {
            font-size: 28px;

            font-weight: 600;
            color: #b4272b;
          }
        }

        .delete {
          width: 120px;
          height: 60px;
          background: #b4272b;
          border-radius: 6px;
          font-size: 28px;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .technicalSupport {
    padding: 10px 0 30px;
    font-size: 20px;
    color: #999999;
    line-height: 1;
    text-align: center;
    background-color: #f6f6f6;
    margin-top: auto;
  }
}

.noData {
  padding-top: 180px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 297px;
      height: 269px;
      margin-bottom: 42px;
    }
    span {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
